import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { projectImage, projectLink } from "./project-icon.module.scss"

const ProjectIcon = ({ project }) => {
    return (
        <Link className={projectLink} to={project.fields["slug"]}
              data-tip={project.frontmatter.title}>
            <GatsbyImage
                image={project.frontmatter.cover.icon.gatsbyImageData}
                placeholder="blurred"
                className={projectImage}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
                key={project.id}/>
        </Link>
    )

}
export default ProjectIcon
