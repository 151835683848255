import React from "react"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBookmark, faCalendarCheck, faComment, faComments, faHistory,
    faMicrophone,
    faNewspaper
} from "@fortawesome/free-solid-svg-icons"

import FeaturedMarker from "../components/featured-marker"
import TalkList from "../components/talk-list"
import MentionList from "../components/mention-list"

import { contentBadge, tableOfContents, tableOfContentsList } from "./academic.module.scss"
import { note, headerIcon } from "./appearances.module.scss"


const AppearancesPage = ({ data }) => {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const upcomingTalks = data.allTalksJson.edges.filter(e => new Date(e.node.date) > today)
    const pastTalks = data.allTalksJson.edges.filter(e => new Date(e.node.date) < today)


    const interviews = data.allPressMentionsJson.edges.filter(e => e.node.interview)
    const mentions = data.allPressMentionsJson.edges.filter(e => !e.node.interview)

    return (
        <>
            <Seo title="Public Appearances"/>
            <h1>Public Appearances</h1>
            <p>
                As a researcher, I often receive the opportunity to talk about
                my <Link to={"/projects"}>projects</Link> and
                my <Link to={"/academic"}>creative AI research</Link> publicly.
                News outlets have also regularly interviewed me, or reported on my projects.
                This page lists all these types of public appearances.
            </p>
            <p>
                If you'd like to interview me or invite me for a talk, you can contact me
                on my <Link to={"/contact"}>contact form</Link>.
            </p>

            <p className="text-secondary">
                <FeaturedMarker featured={true}/> Talks and articles marked in blue are
                recommended for gaining quick insights into my research interests.
            </p>

            <section className={tableOfContents}>
                <h2>
                    <FontAwesomeIcon icon={faBookmark}
                                     className={headerIcon}
                                     size={"sm"}/>
                    Table of Contents
                </h2>
                <div>
                    <ul className={"fa-ul " + tableOfContentsList}>
                        <li>
                            <FontAwesomeIcon icon={faMicrophone} className={headerIcon} listItem/>
                            <AnchorLink to="/appearances#talks">
                                Talks
                                <span className={contentBadge}>{data.allTalksJson.edges.length}</span>
                            </AnchorLink>
                            {upcomingTalks.length > 0 && <ul className={"fa-ul " + tableOfContentsList}>
                                <li>
                                    <FontAwesomeIcon icon={faCalendarCheck} className={headerIcon} listItem/>
                                    <AnchorLink to="/appearances#upcoming">
                                        Upcoming Talks
                                        <span className={contentBadge}>{upcomingTalks.length}</span>
                                    </AnchorLink>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faHistory} className={headerIcon} listItem/>
                                    <AnchorLink to="/appearances#past">
                                        Past Talks
                                        <span className={contentBadge}>{pastTalks.length}</span>
                                    </AnchorLink>
                                </li>

                            </ul>
                            }
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faNewspaper} className={headerIcon} listItem/>
                            <AnchorLink to="/appearances#press">
                                Press
                                <span className={contentBadge}>{data.allPressMentionsJson.edges.length}</span>
                            </AnchorLink>
                            <ul className={"fa-ul " + tableOfContentsList}>
                                <li>
                                    <FontAwesomeIcon icon={faComments} className={headerIcon} listItem/>
                                    <AnchorLink to="/appearances#interviews">
                                        Interviews
                                        <span className={contentBadge}>{interviews.length}</span>
                                    </AnchorLink>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faComment} className={headerIcon} listItem/>
                                    <AnchorLink to="/appearances#mentions">
                                        Mentions
                                        <span className={contentBadge}>{mentions.length}</span>
                                    </AnchorLink>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </section>


            <section id={"talks"}>
                <h2>
                    <FontAwesomeIcon icon={faMicrophone} className={headerIcon}/>
                    Talks
                </h2>
                <p>
                <span>
                A list of public talks I've presented over the years, or will present in the near future.
                    Excluded are many internal talks <em> (e.g. within <Link
                    to={"/academic#position"}>our research lab</Link>)</em> and private workshops </span><em>
                    <span>
                        (e.g. <Link to={"/tag/improv"}>improv</Link> workshops for <Link
                        to={"/preparee"}>Preparee</Link> & others)
                    </span>
                </em>.
                </p>
                {upcomingTalks && <section id={"upcoming"}
                                           className={upcomingTalks.length ? "" : "invisible"}>
                    <h3>
                        <FontAwesomeIcon icon={faCalendarCheck} className={headerIcon}/>
                        Upcoming Talks
                    </h3>
                    <TalkList talks={upcomingTalks}/>
                </section>}
                {pastTalks && <section id={"past"}>
                    <h3
                        className={upcomingTalks.length ? "" : "invisible"}>
                        <FontAwesomeIcon icon={faHistory} className={headerIcon}/>
                        Past Talks
                    </h3>
                    <TalkList talks={pastTalks}/>
                </section>}
            </section>
            <section id={"press"}>
                <h2>
                    <FontAwesomeIcon icon={faNewspaper} className={headerIcon}/>
                    Press
                </h2>
                <p>
                    A list of news and other media outlets mentioning my work, or
                    interviewing me about my projects or my research
                    into computational humor and creative artificial intelligence.
                    {/*A non-exhaustive list of news and other media outlets mentioning my work.*/}
                    <br/>
                    <span className={note}>
                    <em>
                        <strong>
                            Note: {" "}
                        </strong>
                        Listing an item here does not imply endorsement of its content.
                        {/*There are sadly some articles in the list which strongly twisted my words in quotes, likely in order to make the article more "interesting".*/}
                    </em>
                </span>
                </p>
                <section>
                    <h3 id={"interviews"}>
                        <FontAwesomeIcon icon={faComments} className={headerIcon}/>
                        Interviews
                    </h3>
                    <MentionList mentions={interviews} displayProjects={true}/>
                </section>
                <section>
                    <h3 id={"mentions"}>
                        <FontAwesomeIcon icon={faComment} className={headerIcon}/>
                        Mentions
                    </h3>
                    <MentionList mentions={mentions} displayProjects={true}/>
                </section>
            </section>
        </>
    )
}

export default AppearancesPage

export const query = graphql`
    query {
        allPressMentionsJson(sort: { fields: [date, hour], order: [DESC, DESC] }) {
            edges {
                node {
                    id
                    ...MentionFragment
                }
            }
        }
        allTalksJson(sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    id
                    ...TalkFragment
                }
            }
        }
    }
`
