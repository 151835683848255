const { faMicrophone, faChalkboardTeacher, faTicketAlt, faGamepad } = require("@fortawesome/free-solid-svg-icons")

const talkTypes = {
    "conference": {
        name: "Conference talk",
        icon: faMicrophone
    },
    "tutorial": {
        name: "Tutorial",
        icon: faChalkboardTeacher
    },
    "talk": {
        name: "Invited talk",
        icon: faTicketAlt
    },
    "demo": {
        name: "Demonstration",
        icon: faGamepad
    }
}

const getTalkType = function(type) {
    if (type) {
        const lowercasedType = type.toLowerCase()
        if (talkTypes.hasOwnProperty(lowercasedType)) {
            return talkTypes[lowercasedType]
        }
    }
    return {
        name: "Talk",
        icon: faMicrophone
    }
}

const getTalkTypeName = function(type) {
    const talkType = getTalkType(type)
    if (talkType && talkType.name) {
        return talkType.name
    }
    return type
}

const getTalkTypeIcon = function(type) {
    const talkType = getTalkType(type)
    if (talkType && talkType.icon) {
        return talkType.icon
    }
    return faMicrophone
}

function isSlideShareUrl(slidesUrl) {
    return slidesUrl && slidesUrl.startsWith("https://www.slideshare.net")
}

module.exports = { getTalkTypeName, getTalkTypeIcon, isSlideShareUrl }


