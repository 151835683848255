// extracted by mini-css-extract-plugin
export var backgroundColor = "#141821";
export var badge = "talk-list-module--badge--7266a";
export var badges = "talk-list-module--badges--654aa";
export var borderRadius = ".15rem";
export var date = "talk-list-module--date--a7613";
export var faBadge = "talk-list-module--fa-badge--7be09";
export var imageButton = "talk-list-module--image-button--37bc2";
export var left = "talk-list-module--left--98e1d";
export var location = "talk-list-module--location--4091c";
export var locationContainer = "talk-list-module--location-container--db693";
export var locationExtra = "talk-list-module--location-extra--a9dd1";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var talkContainer = "talk-list-module--talk-container--5700f";
export var title = "talk-list-module--title--6fb90";