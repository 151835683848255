import React from "react"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { featuredLabel } from "./feature-marker.module.scss"

const FeaturedMarker = ({ featured }) => {
    if (featured) {
        return <span className={featuredLabel}
                     data-tip={"Recommended"}
                     data-offset={"{'bottom':10}"}>
            <FontAwesomeIcon icon={faStar}
                             size={"xs"}/>
        </span>
    } else {
        return <></>
    }
}

export default FeaturedMarker
