// extracted by mini-css-extract-plugin
export var articleContent = "mention-list-module--article-content--18bb6";
export var articleInfo = "mention-list-module--article-info--05b3d";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var content = "mention-list-module--content--cf6b1";
export var date = "mention-list-module--date--81e0d";
export var icon = "mention-list-module--icon--70652";
export var iconOrLogo = "mention-list-module--icon-or-logo--072e2";
export var imageButton = "mention-list-module--image-button--7b7cc";
export var maxModalWidth = "48em";
export var mentionsContainer = "mention-list-module--mentions-container--6bf2d";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var note = "mention-list-module--note--f0a34";
export var project = "mention-list-module--project--71766";
export var projects = "mention-list-module--projects--c16c8";
export var publisher = "mention-list-module--publisher--5811c";
export var publisherTypeIcon = "mention-list-module--publisher-type-icon--166b7";
export var timestamps = "mention-list-module--timestamps--9cdfa";
export var title = "mention-list-module--title--98306";
export var titleAndProject = "mention-list-module--title-and-project--6b6ac";